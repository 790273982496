import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "../../uikit/box/Box";
import Text from "../../uikit/text/Text";
import Button from "../../uikit/button/Button";
import { verifyCode } from "../../../stores/AuthService";

const STATES = Object.freeze({
  INITIAL: "INITIAL",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

function Verify({ match, history }) {
  const [state, setState] = useState(STATES.INITIAL);

  useEffect(() => {
    if (match.params.code) {
      verifyCode(match.params.code)
        .then((resp) => {
          console.log("resp from verify email: ", resp);
          setState(STATES.SUCCESS);
        })
        .catch((e) => {
          console.log("verify error", e);
          setState(STATES.ERROR);
        });
    } else {
      return history.push("/");
    }
  }, []);

  return (
    <>
      <Box p={[6, 7]} bg="primarys.0" maxWidth="416px" boxShadow={1}>
        {{
          [STATES.INITIAL]: () => null,
          [STATES.SUCCESS]: () => (
            <>
              <Text color="#ffffff" mb={20}>
                Your email address was verified! Please login.
              </Text>
              <Button as={Link} to="/">
                Login
              </Button>
            </>
          ),
          [STATES.ERROR]: () => (
            <>
              <Text textAlign="center" color="accents.0">
                Your email address could not be verified.
              </Text>
            </>
          ),
        }[state]()}
      </Box>
    </>
  );
}

export default Verify;
